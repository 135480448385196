require('./wave')
require('./parallax')
require('./counting')
require('./tab')
require('./language-switcher')
require('./mobile-menu')
require('./map')
require('./sticky')
require('./filter-mob')
require('./lightbox')
require('./successPopup')
require('./slider')
require('./sticky-catalog-header')
require('./form-submit')
require('./icon')
// require('./onlyNumber')
require('./inputMask')
