const successPopup = document.querySelector('.popup.success')
const html = document.querySelector('html')

if (!!successPopup) {
  successPopup.addEventListener('click', ({target}) => {
    if (target.classList.contains('close')) {
      html.classList.remove('lock-scroll')
      successPopup.remove()
    }
  })
}


