const events = 'load scroll'
const lg = 960

function catalogHeader() {
  const catalogSt = document.querySelector('.catalogs .main')
  const item = document.querySelector('.catalogs .top-banner-gray')

  if (!catalogSt) return

  let targetPosition = {
      top: window.pageYOffset + catalogSt.getBoundingClientRect().top
    },
    windowPosition = {
      top: window.pageYOffset
    }

  if (targetPosition.top > windowPosition.top) {
    item.classList.remove('fixed')
  } else if (targetPosition.top < windowPosition.top) {
    item.classList.add('fixed')
  }
}

events.split(' ').forEach(ev => {
  if (window.innerWidth < lg) {
    window.addEventListener(ev, () => {
      catalogHeader()
    })
  }
})

