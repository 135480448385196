var a = document.querySelector('#sticky'), b = null, P = 0
const events = 'load resize'
const lg = 960

events.split(' ').forEach(ev => {
  if (window.innerWidth > lg && !!a) {
    window.addEventListener(ev, () => {
      window.addEventListener('scroll', stickyBlock)
      document.body.addEventListener('scroll', stickyBlock)
    })
  }
})

function stickyBlock() {
  if (b == null) {
    var Sa = getComputedStyle(a, ''), s = ''
    for (let i = 0; i < Sa.length; i++) {
      if (Sa[i].indexOf('overflow') == 0 || Sa[i].indexOf('padding') == 0 || Sa[i].indexOf('border') == 0 || Sa[i].indexOf('outline') == 0 || Sa[i].indexOf('box-shadow') == 0 || Sa[i].indexOf('background') == 0) {
        s += Sa[i] + ': ' + Sa.getPropertyValue(Sa[i]) + '; '
      }
    }
    b = document.createElement('div')
    b.style.cssText = s + ' box-sizing: border-box; width: ' + a.offsetWidth + 'px;'
    a.insertBefore(b, a.firstChild)
    var l = a.childNodes.length
    for (let i = 1; i < l; i++) {
      b.appendChild(a.childNodes[1])
    }
    a.style.height = b.getBoundingClientRect().height + 'px'
    a.style.padding = '0'
    a.style.border = '0'
  }
  var Ra = a.getBoundingClientRect(),
    R = Math.round(Ra.top + b.getBoundingClientRect().height - document.querySelector('footer').getBoundingClientRect().top + 80)
  if ((Ra.top - P) <= 0) {
    if ((Ra.top - P) <= R) {
      b.className = 'stop'
      b.style.top = -R + 'px'
    } else {
      b.className = 'sticky'
      b.style.top = P + 'px'
    }
  } else {
    b.className = ''
    b.style.top = ''
  }
  window.addEventListener('resize', function () {
    a.children[0].style.width = getComputedStyle(a, '').width
  }, false)
}
