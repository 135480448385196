const btn = document.querySelector('.menu-icon-toggle')
const html = document.querySelector('html')
const menu = document.querySelector('.header')
const overlay = document.querySelector('.overlay')

if (btn) {
  btn.addEventListener('click', () => element())
  overlay.addEventListener('click' , () => element())
}

function element() {
  html.classList.toggle('lock-scroll')
  menu.classList.toggle('open-menu')
  overlay.classList.toggle('show')
}

