let qs = (selector, context = document) => context.querySelector(selector)
let qsa = (selector, context = document) =>
  Array.from(context.querySelectorAll(selector))
const hrefOrder = 'order'
const html = document.querySelector('html')

function openLightbox(e) {
  e.preventDefault()
  const gitem = e.currentTarget,
    itemimg = qs('img', gitem)

  if (e.currentTarget.getAttribute('href') === hrefOrder) {
    const popupbox = qs('.popup')
    popupbox.querySelector('form').setAttribute('data-product', e.target.dataset.index)
    html.classList.add('lock-scroll')
    popupbox.classList.add('open')
  }

  const lightbox = qs('.lightbox'),
    lightboximg = qs('.lb-img', lightbox)

  lightboximg.onload = () => {
    html.classList.add('lock-scroll')
    lightbox.classList.add('open')
  }

  if (!!itemimg) {
    const listImg = itemimg.dataset.image.split(',')

    if (itemimg.dataset.image) {
      listImg.forEach((el, inx) => {
        let img = document.createElement('img')
        img.classList.add('image')
        img.src = el.replace(/[(\[\])\\"]/g, '')
        lightboximg.parentNode.append(img)
        document.querySelector('.paginator .all').innerHTML = listImg.length + 1
      })
      setTimeout( () => sliderInit(lightboximg.parentNode), 1000)
    }
    lightboximg.setAttribute('src', itemimg.getAttribute('src'))
  }
}

function closeLightbox(e) {
  e.preventDefault()
  const lightbox = e.currentTarget.closest('.lightbox')
  const popup = e.currentTarget.closest('.popup')
  if (!!lightbox) {
    html.classList.remove('lock-scroll')
    let images = lightbox.querySelectorAll('img')
    if (images.length > 1) {
      images.forEach(img => {
        if (img.classList.contains('image')) {
          img.remove()
        }
      })
      $('.lb-content__wrapper').slick('unslick')
    }
    lightbox.classList.remove('open')
  }
  if (!!popup) {
    html.classList.remove('lock-scroll')
    popup.classList.remove('open')
  }
}

function sliderInit(sliderItem) {
  const slickOpts = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '20px',
    easing: 'swing',
    prevArrow: '<div class=\'slick-arrow slick-prev pull-left\'><img src=\'/images/icons/arrow-white.svg\' alt=\'\'></div>',
    nextArrow: '<div class=\'slick-arrow slick-next pull-right\'><img src=\'/images/icons/arrow-white.svg\' alt=\'\'></div>',
    arrows: true,
    infinite: false,
    speed: 700,
  }
  $('.lb-content__wrapper').slick(slickOpts).on('init afterChange', function (event, slick, currentSlide, nextSlide) {
    $('.lb-content .current').html(1)
    $('.lb-content .all').html(slick.slideCount)
  })
}

document.addEventListener('DOMContentLoaded', () => {

  const gitems = qsa('.product__image')
  const openPopup = document.querySelectorAll('a[href*="order"]')

  gitems.forEach(el => el.addEventListener('click', openLightbox))
  openPopup.forEach(el => el.addEventListener('click', openLightbox))

  const lbclose = qs('.lightbox .close')
  const popupclose = qs('.popup .close')
  if (lbclose) lbclose.addEventListener('click', closeLightbox)
  if (popupclose) popupclose.addEventListener('click', closeLightbox)
})
