function parallax() {
  let item = document.getElementById('parallax-item')

  if  (!item) return

  let targetPosition = {
      bottom: window.pageYOffset + item.getBoundingClientRect().bottom
    },
    windowPosition = {
      top: window.pageYOffset
    }

  if  (targetPosition.bottom > windowPosition.top ) {
    let yPos = window.pageYOffset / item.dataset.speed
    yPos = -yPos

    let coords = '50% ' + yPos + 'px'

    item.style.backgroundPosition = coords
  }
}

window.addEventListener('scroll',  () => {
  parallax()
})
