import { TweenMax } from 'gsap'

const numberBlock = document.querySelector('.counting')
const events = 'load scroll'
const speed = 4
const scores = {}

if (!!numberBlock) {
  numberBlock.querySelectorAll('.counting__number').forEach((item, inx) => {
    scores['score' + `${inx + 1}`] = {score: 0, end: item.dataset.number}
  })

  events.split(' ').forEach(event => {
    window.addEventListener(event, () => {
      const updateNumber = false

      let targetPosition = {
          top: window.pageYOffset + numberBlock.getBoundingClientRect().top,
          bottom: window.pageYOffset + numberBlock.getBoundingClientRect().bottom
        },
        windowPosition = {
          top: window.pageYOffset,
          bottom: window.pageYOffset + document.documentElement.clientHeight
        }
      if (targetPosition.bottom > windowPosition.top && targetPosition.top < windowPosition.bottom && !updateNumber) {
        let leng = Object.keys(scores).length + 1

        for (let index = 1; index < leng; index++) {
          TweenMax.to(scores[`score${index}`], speed,
            {
              score: scores[`score${index}`].end,
              onUpdate: updateHandler,
              onComplete: addPlus,
              onUpdateParams: [index]
            }
          )
        }
      }
    })
  })
}

function addPlus() {
  numberBlock.querySelectorAll('.counting__number span').forEach(item => {
    item.innerHTML = '+'
  })
}

function updateHandler(index) {
  let numberBlock = document.querySelector('.counting__number[data-target="' + index + '"] div')
  numberBlock.innerHTML = scores[`score${index}`].score.toFixed(0)
}
