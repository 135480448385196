const activItems = document.querySelectorAll('.switcher__item.active .switcher__link')

activItems.forEach( el => {
  el.addEventListener('click', ({target}) => {
    target.parentNode.classList.toggle('show')
  })
})

$(document).mouseup(function (e) {
  let container = $(".switcher");
  let item = $('.switcher .active')
  if (container.has(e.target).length === 0) {
    item.removeClass('show');
  }
})
