import 'slick-slider'

$(window).on('resize load', () => {
  if (window.innerWidth < 960) {
    $('.matrix__wrapper').slick({
      infinite: false,
      speed: 600,
      centerPadding: '110px',
      prevArrow: '<div class=\'slick-arrow slick-prev pull-left\'><img src=\'/images/icons/arrow.svg\' alt=\'\'></div>',
      nextArrow: '<div class=\'slick-arrow slick-next pull-right\'><img src=\'/images/icons/arrow.svg\' alt=\'\'></div>',
      arrows: true,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            centerMode: true,
            centerPadding: '70px',
            arrows: true,
            infinite: false,
            slidesToShow: 1,
            respondTo: '180px'
          }
        },
        {
          breakpoint: 800,
          settings: {
            centerPadding: '20px',
            arrows: true,
            infinite: false,
            slidesToShow: 2,
          }
        }
      ]
    })
  }
})


