import IMask from 'imask';

const phones =document.querySelectorAll('input[type="tel"]')

phones.forEach( phone => {
  IMask(
    phone, {
      mask: '+{0}{0} (000)000-00-00'
    });
})
