window.initMap = () => {
  const currentPos = document.querySelector('.contacts__map > div')

  if (!currentPos) return

  const markerIcon = {
    url: '/images/icons/marker.svg',
  }

  let pos = {
    lat: +currentPos.dataset.lat,
    lng: +currentPos.dataset.lng
  }
  let map = new google.maps.Map(
    document.getElementById('map'), {
      zoom: 15,
      center: pos,
      disableDefaultUI: true
    })

  let marker = new google.maps.Marker({
    position: pos,
    map: map,
    icon: markerIcon
  })
}
