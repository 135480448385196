const mobFilter = document.querySelector('.filter-mob')
const btn = document.querySelector('.two-item .text')
const html = document.querySelector('html')
const events = 'load scroll'

if (!!mobFilter) {

  btn.addEventListener('click', () => {
    html.classList.toggle('lock-scroll')
    mobFilter.classList.toggle('open')
  })

  events.split(' ').forEach(ev => {
    document.addEventListener(ev, () => {
      if (window.pageYOffset > 60) {
        mobFilter.style.top = '60px'
        mobFilter.style.height = 'calc(100vh - 60px)'
      } else {
        mobFilter.style.top = '120px'
        mobFilter.style.height = 'calc(100vh - 120px)'
      }
    })
  })

}


