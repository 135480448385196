const tabs = document.querySelectorAll('.tabs')

tabs.forEach( tab => {
  tab.addEventListener('click', ({target}) => {

    if (target.classList.contains('tabs__item')) {
      removeActive(tab)
      target.classList.add('active')

      tab.querySelectorAll('.tabs__content').forEach( item => {

        if (item.dataset.content === target.dataset.tab) {
          item.classList.add('active')
        }

      })
    }
  })
})

function removeActive(tab) {
  tab.querySelectorAll('.active').forEach( item => item.classList.remove('active'))
}

