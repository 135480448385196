$('.popup form').on('submit', function (e) {
  e.preventDefault()
  let form = $(this)
  let inputName = $('#name')
  let inputEmail = $('#phone')
  let inputDescription = $('#email')
  let inputСomment = $('#comment')
  let url = `${form.data('action')}/${form.data('product')}`
  let sendData = new FormData
  sendData.append('name', inputName.val())
  sendData.append('phone', inputEmail.val().replace(/[+()\s-]/g, ''))
  sendData.append('email', inputDescription.val())
  sendData.append('comment', inputСomment.val())

  $.ajax({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    url: url,
    type: 'POST',
    processData: false,
    contentType: false,
    data: sendData,
    success: function (result) {
      if (result.status === true) {
        $('.popup').removeClass('open')
        $('.popup.success').addClass('open')
        setTimeout(function () {
          $('.popup.success').removeClass('open')
          $('html').removeClass('lock-scroll')
          $(form)[0].reset()
        }, 2000)
      }
    },
  })
})

$('.communication form').on('submit', function (e) {
  e.preventDefault()
  let form = $(this)
  let inputName = $('#name')
  let inputEmail = $('#phone')
  let inputDescription = $('#email')
  let inputQuestion = $('#question')
  let inputComment = $('#comment')
  let url = form.data('action')
  let sendData = new FormData
  sendData.append('name', inputName.val())
  sendData.append('phone', inputEmail.val().replace(/[+()\s-]/g, ''))
  sendData.append('email', inputDescription.val())
  sendData.append('question', inputQuestion.val())
  sendData.append('comment', inputComment.val())

  $.ajax({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    url: url,
    type: 'POST',
    processData: false,
    contentType: false,
    data: sendData,
    success: function (result) {
      if (result.status === true) {
        $('.popup.success').addClass('open')
        setTimeout(function () {
          $('.popup.success').removeClass('open')
          $(form)[0].reset()
        }, 2000)
      }
    },
  })
})

$('.service-form form').on('submit', function (e) {
  e.preventDefault()
  let form = $(this)
  let inputName = $('#name')
  let inputEmail = $('#phone')
  let inputDescription = $('#email')
  let inputQuestion = $('#service')
  let inputComment = $('#comment')
  let url = form.data('action')
  let sendData = new FormData
  sendData.append('name', inputName.val())
  sendData.append('phone', inputEmail.val().replace(/[+()\s-]/g, ''))
  sendData.append('email', inputDescription.val())
  sendData.append('comment', inputComment.val())
  sendData.append('service', inputQuestion.val())

  $.ajax({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    url: url,
    type: 'POST',
    processData: false,
    contentType: false,
    data: sendData,
    success: function (result) {
      if (result.status === true) {
        $('.popup.success').addClass('open')
        setTimeout(function () {
          $('.popup.success').removeClass('open')
          $(form)[0].reset()
        }, 2000)
      }
    },
  })
})

$('.partnership form').on('submit', function (e) {
  e.preventDefault()
  let form = $(this)
  let inputName = $('#name')
  let inputEmail = $('#phone')
  let inputDescription = $('#email')
  let inputSpecialization = $('#specialization')
  let inputLocation = $('#location')
  let inputComment = $('#comment')
  let url = form.data('action')
  let sendData = new FormData
  sendData.append('name', inputName.val())
  sendData.append('phone', inputEmail.val().replace(/[+()\s-]/g, ''))
  sendData.append('email', inputDescription.val())
  sendData.append('comment', inputComment.val())
  sendData.append('specialization', inputSpecialization.val())
  sendData.append('location', inputLocation.val())

  $.ajax({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    url: url,
    type: 'POST',
    processData: false,
    contentType: false,
    data: sendData,
    success: function (result) {
      if (result.status === true) {
        $('.popup.success').addClass('open')
        setTimeout(function () {
          $('.popup.success').removeClass('open')
          $(form)[0].reset()
        }, 1000)
      }
    },
  })
})

